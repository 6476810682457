// console.log('Setting up Webpack 5. Hurray!!');

// IMPORT UTILITY FUNCTIONS AND OBJECTS FROM UTILITIES
import {
  dom
} from './utilities';

// IMPORT FOR VALIDATOR
import './formValidator';

// IMPORT FOR VIDEOS
// import mp4 from './videoLoader.js';
import b2s from '../video/B2S_no_p_1.mp4';
import q3nl from "../newsletters/final_quarter_3_nl.pdf";
import blazeYLES from "../newsletters/2023_BLAZE_Youth_Leadership_Empowerment_Summit.pdf";

// document.getElementById("pdf-file").href = PDF;

// Import main style sheet into javascript bundle
import styles from '../scss/main.module.scss';

// Import bootstrap javascript bundle as a whole
// import * as bootstrap from 'bootstrap';
const bootstrap = require('bootstrap/dist/js/bootstrap.bundle');

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// Import jQuery as a module
import $ from 'jquery';
window.$ = window.jQuery = $;

{
  // Initialize Swiper

  const customSlider = document.querySelectorAll('.swiper-container');

  for (let i = 0; i < customSlider.length; i++) {

    customSlider[i].classList.add('swiper-container-' + i);

    var slider = new Swiper('.swiper-container-' + i, {
      spaceBetween: 30,
      centeredSlides: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      // },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        autoplayTimeLeft(s, time, progress) {
          dom.progressCircle.style.setProperty('--progress', 1 - progress);
          dom.progressContent.textContent = `${Math.ceil(time / 1000)}s`;
        },
      },
    });

  }

  /*
  const swiper = new Swiper('.jarsOfGlorySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    // autoplay: {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      autoplayTimeLeft(s, time, progress) {
        dom.progressCircle.style.setProperty('--progress', 1 - progress);
        dom.progressContent.textContent = `${Math.ceil(time / 1000)}s`;
      },
    },
  });
  */
}


// INITIALIZE/ENABLE BOOTSTRAP TOOLTIP FOR ALL TOOLTIP ELEMENTS

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

// MANUALLY CREATE/UPDATE TOOLTIP
// let refreshPage = new bootstrap.Tooltip(dom.refreshPage);
// refreshPage.show();

// Pass current date into footer
const footerDate = new Date().getFullYear();
const footerPara = `<p class="copyright-text text-center text-white mb-0">Copyright &copy; ${footerDate} Jars of Glory. All rights reserved</p>`;
const getFullYearArr = Array.from(dom.getFullYear);
for (const cur of getFullYearArr) {
  cur.innerHTML = footerPara;
}

{
  // Inject Site Footer Details Information into every "siteFooter" class
  const siteFooter = `<div class="container-fluid sectionBackgroundColor">
  <div class="row px-lg-5">
    <div class="ui-footer-autofit-grid pt-4">
      <div id="footer-col-about">
        <p class="small mb-1">About</p>
        <ul class="list-unstyled ps-0 lh-lg">
          <li><a href="#home" class="footer-item">Home</a></li>
          <li><a href="#about" class="footer-item">Jars of Glory</a></li>
          <li><a href="#about" class="footer-item">Core Values</a></li>
          <li><a href="#about" class="footer-item">Our Vision</a></li>
          <li><a href="#about" class="footer-item">Our Mission</a></li>
          <li><a href="" class="footer-item"></a></li>
        </ul>
      </div>
      <div id="footer-col-programs">
        <p class="small mb-1">Programs</p>
        <ul class="list-unstyled ps-0 lh-lg">
          <li><a href="#the-bridge-care" class="footer-item">Bridge Care</a></li>
          <li><a href="#the-bridge-care" class="footer-item">Bridge Scholarship</a></li>
          <li><a href="#the-tabitha-care" class="footer-item">Tabitha Care</a></li>
          <li><a href="#tabitha-transitional-housing-support" class="footer-item">Tabitha Transitional
              Housing</a></li>
          <li><a href="#tabitha-financial-education" class="footer-item">Tabitha Financial Education</a></li>
          <li><a href="#tabitha-career-technical-training" class="footer-item">Tabitha Career & Technical
              Training</a></li>
          <li><a href="#tabitha-short-term-recovery-care" class="footer-item">Tabitha Short-Term Recovery
              Care</a></li>
          <li><a href="#glory-house" class="footer-item">Glory House</a></li>
          <li><a href="" class="footer-item"></a></li>
        </ul>
      </div>
      <div id="footer-col-media">
        <p class="small mb-1">Resources</p>
        <ul class="list-unstyled ps-0 lh-lg">
          <li><a href="#news-letters" class="footer-item">News Letters</a></li>
          <li><a href="#resources" class="footer-item">Photo Gallery</a></li>
          <li><a href="#media" class="footer-item">Media</a></li>
        </ul>
      </div>
      <div id="footer-col-partners">
        <p class="small mb-1">Partner With Us</p>
        <ul class="list-unstyled ps-0 lh-lg">
          <li><a href="#give" class="footer-item">Give</a></li>
          <li><a href="#pray" class="footer-item">Pray</a></li>
          <li><a href="#serve" class="footer-item">Serve</a></li>
          <li><a href="#events" class="footer-item">Events</a></li>
          <li><a href="#contact_form" data-transition="none" __data-bs-toggle="modal" __data-bs-target="#contact-details">Contact Us</a></li>
        </ul>
      </div>
      <div id="footer-col-legal">
        <p class="small mb-1">Legal</p>
        <ul class="list-unstyled ps-0 lh-lg">
          <li><a href="#" data-transition="none" data-bs-toggle="modal" data-bs-target="#cookies-details">Terms, Conditions & Cookies
          Policy</a></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row mx-0 pb-3 section-social">
          <div class="py-2 mb-2 text-center">Follow On Social</div>
          <div class="social w-100 d-flex justify-content-center">
                    <a href="https://www.instagram.com/jarsofglory/" class="link instagram" target="_blank"
                        data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on instagram"><span><i
                                class="fa-brands fa-square-instagram social-icon"></i></span></a>
                    <a href=" https://twitter.com/JarsofGlory" class="link twitter" target="_blank"
                        data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on twitter"><span><i class="fa-brands fa-square-x-twitter social-icon"></i></span></a>
                                
                    <a href="https://www.facebook.com/profile.php?id=100091923313664&mibextid=LQQJ4d"
                        class="link facebook" target="_blank" data-bs-toggle="tooltip"
                        data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on facebook"><span><i
                                class="fa-brands fa-square-facebook social-icon"></i></span></a>
                    <a href="https://www.tiktok.com/@jarsofglory" class="link tiktok" target="_blank"
                        data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on tiktok"><span><i class="fa-brands fa-tiktok social-icon"></i></span></a>
                </div>
          </div>
          
  <div class="row mt-3 pb-5 d-none_ d-lg-block_ d-xl-block_">
      <div class="small">Jars of Glory, P.O. BOX 120609, West Melbourne, FL 32904, USA</div>
      <div class="small ms-4_"><i class="fa fa-phone fa-fw"></i> +1-321-831-3191</div>
  </div>
</div>`;
  const siteFooterElement = dom.siteFooter;
  const siteFooterElementArr = Array.from(siteFooterElement);
  for (const cur of siteFooterElementArr) {
    cur.innerHTML = siteFooter;
  }
}

/*
{
  // Inject Site Footer Details Information into every "siteFooter" class
  const siteFooter = `
  <div class="container-fluid sectionBackgroundColor mobileLastSection pt-3">
          <div class="row">
            <div class="col-sm-4 d-none d-lg-block d-xl-block">
              <div class="card siteCardNoBackground">
                <div class="card-header bg-secondary text-white">
                  GET IN TOUCH WITH US
                </div>
                <div class="card-body">
                  <address class="card-text">
                  Jars of Glory<br>
                  P.O. BOX  120609<br>
                  West Melbourne, FL 32904<br>
                  USA                  
                  </address>
                  <div class="input-group margin-bottom-sm">
                    <span class="input-group-addon"><i class="fa fa-phone fa-fw"></i></span>
                    <p>+1-321-831-3191</p>
                  </div>
                  <div class="input-group margin-bottom-sm">
                    <span class="input-group-addon"><i class="fa fa-envelope fa-fw"></i></span>
                    <a data-auto-recognition="true" href="mailto:info@jarsofglory.org" class="">Email Us for all inquiries</a>
                  </div>
                  <div class="input-group margin-bottom-sm">
                    <span class="input-group-addon"><i class="fa fa-envelope fa-fw"></i></span>
                    <a data-auto-recognition="true" href="mailto:finance@jarsofglory.org" class="">Email us for giving/financial inquiries</a>
                  </div>
                  
                  
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="card siteCardNoBackground">
                <div class="card-header bg-secondary text-white">
                  QUICK LINKS
                </div>
                <div class="card-body">
                  <ul style="color: var(--bs-dark);" class="nav flex-sm-column">
                    <li class="nav-item">
                      <a class="nav-link" href="#home" data-transition="none">Home</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#about" data-transition="none">About</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cta" href="#about" data-transition="none">Mission</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cta" href="#about" data-transition="none">Vision</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cta" href="#the-bridge-care" data-transition="none">The Bridge Care</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cta" href="#the-tabitha-care" data-transition="none">The Tabitha Care</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link cta" href="#glory-house" data-transition="none">Glory House</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="card siteCardNoBackground">
                <div class="card-header bg-secondary text-white">
                  QUICK LINKS
                </div>
                <div class="card-body">
                  <ul style="color: var(--bs-dark);" class="nav flex-sm-column">
                    <li class="nav-item">
                      <a class="nav-link" href="#programs" data-transition="none">Our Programs</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#events" data-transition="none">Upcoming and Past Events</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#partner" data-transition="none">Partner with us</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#give" data-transition="none">Give</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#serve" data-transition="none">Serve</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#resources" data-transition="none">Resources</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#" data-transition="none" data-bs-toggle="modal" data-bs-target="#cookies-details">Cookies & Privacy</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            
          </div>
          <div class="row mx-0">
          <div class="bg-secondary text-white py-2 mb-2 text-center text-uppercase">Follow On Social</div>
          <div class="social w-100 d-flex justify-content-center">
                    <a href="https://www.instagram.com/jarsofglory/" class="link instagram" target="_blank"
                        data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on instagram"><span><i
                                class="fa-brands fa-square-instagram social-icon"></i></span></a>
                    <a href=" https://twitter.com/JarsofGlory" class="link twitter" target="_blank"
                        data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on twitter"><span><i
                                class="fa-brands fa-square-twitter social-icon"></i></span></a>
                    <a href="https://www.facebook.com/profile.php?id=100091923313664&mibextid=LQQJ4d"
                        class="link facebook" target="_blank" data-bs-toggle="tooltip"
                        data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on facebook"><span><i
                                class="fa-brands fa-square-facebook social-icon"></i></span></a>
                    <a href="https://www.tiktok.com/@jarsofglory" class="link tiktok" target="_blank"
                        data-bs-toggle="tooltip" data-bs-custom-class="custom-tooltip" data-bs-placement="bottom"
                        title="Follow Us on tiktok"><span><i class="fa-brands fa-tiktok social-icon"></i></span></a>
                </div>
          </div>
        </div>`;
  const siteFooterElement = dom.siteFooter;
  const siteFooterElementArr = Array.from(siteFooterElement);
  for (const cur of siteFooterElementArr) {
    cur.innerHTML = siteFooter;
  }
}
*/


// Show Contact details
const office = document.getElementById('office');
const contactUs = document.getElementById('contact');
const closeBtn = document.getElementById('closeBtn');
const contactDetails = document.querySelector('.contactDetails');
const closeFindUs = new bootstrap.Tooltip(closeBtn);

const moreMenu = document.getElementById('more-menu');
const moreOptions = document.querySelector('.more-options');
const moreCloseButton = document.getElementById('more-closeBtn');
const closeMoreOptions = new bootstrap.Tooltip(moreCloseButton);

office.addEventListener('click', (e) => {
  contactDetails.classList.add('show');
  moreOptions.classList.add('d-none');

  // MANUALLY CREATE/UPDATE TOOLTIP
  closeFindUs.show();
  closeMoreOptions.hide();
});

closeBtn.addEventListener('click', (e) => {
  contactDetails.classList.remove('show');
  closeFindUs.hide();
});
contactUs.addEventListener('click', (e) => {
  contactDetails.classList.remove('show');
  moreOptions.classList.add('d-none');
  closeFindUs.hide();
  closeMoreOptions.hide();
});

// Show / Hide Quality care commission rating

if (moreMenu) {
  moreMenu.addEventListener('click', (e) => {
    if (e.target.closest('#more-menu')) {
      moreOptions.classList.remove('d-none');
      contactDetails.classList.remove('show');

      // MANUALLY CREATE/UPDATE TOOLTIP
      closeMoreOptions.show();
      closeFindUs.hide();
    }
  });
}

moreCloseButton.addEventListener('click', (e) => {
  moreOptions.classList.add('d-none');
  closeMoreOptions.hide();
});

// Go back to top
dom.backToTopBtn.addEventListener('click', () => {
  dom.appBody.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
});

const backToTop = () => {
  var scrollY = window.scrollY;
  var navTop = 110;
  // console.log(scrollY);
  // window.scrollY > navTop ? dom.backToTopBtn.classList.add('show') :dom.backToTopBtn.classList.remove('show');
  if (window.scrollY > navTop) {
    dom.backToTopBtn.classList.add('show');
  } else if (window.scrollY < navTop) {
    dom.backToTopBtn.classList.remove('show');
  }
};
window.addEventListener('scroll', backToTop);

{
  // GDPR

  // Prevent display of Cookie and Privacy notice banner once user acknowledges it
  if (localStorage.gdprAccepted) {
    dom.jsGdpr.classList.add('gdprRemove');
    dom.jsCover.classList.add('gdprRemove');
  }

  var gdprNotice = 'User Closed Notification';
  dom.jsCloseGdpr.addEventListener('click', () => {
    // Reverse Animate GDPR
    const gdpr = dom.gdpr;
    var position = -2;
    var callBackInterval = setInterval(animate, 10);

    function animate() {
      if (position === -235) {
        clearInterval(callBackInterval);
      } else {
        position = position - 1; //position++;
        gdpr.style.bottom = position + 'px';
      }
    }

    setTimeout(() => {
      dom.jsGdpr.classList.remove('gdpr');
      dom.jsGdpr.classList.add('gdprRemove');
      dom.jsCover.classList.add('gdprRemove');
      localStorage.setItem('gdprAccepted', gdprNotice);
    }, 1000);
  });

  // Animate GDPR
  const gdpr = dom.gdpr;
  var position = -180;
  var callBackInterval = setInterval(animate, 10);

  function animate() {
    if (position === -2) {
      clearInterval(callBackInterval);
    } else {
      position = position + 1; //position++;
      gdpr.style.bottom = position + 'px';
    }
  }
}

(() => {
  const emailForm = document.querySelector('.sendEmail');
  if (emailForm) {
    emailForm.addEventListener('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }
})();


{
  //Inject site Logo and DeskTop Menu into relevant pages
  const navMenu = `
  <ul class="navigation shadow">
  <div class="ui_Content_Header"></div>
        <li class="linkBox hl-home">
            <a href="#home" data-transition="none">Home</a>
            <span class="menuTip">Welcome</span>
        </li>
        <li class="linkBox hl-about">
            <a href="#about" data-transition="none">About</a>
            <span class="menuTip">Who we are</span>
        </li>
        <li class="linkBox hl-programs">
            <a href="#programs" data-transition="none">Programs</a>
            <span class="menuTip">What we do</span>
        </li>
        <li class="linkBox hl-events">
            <a href="#events" data-transition="none">Events</a>
            <span class="menuTip">Upcoming events</span>
        </li>
        <li class="linkBox hl-partner">
            <a href="#partner" data-transition="none">Partner With Us</a>
            <span class="menuTip">Ways to support us</span>
        </li>
        <li class="linkBox hl-resources">
            <a href="#resources" data-transition="none">Resources</a>
            <span class="menuTip">Useful materials</span>
        </li>
        <li class="linkBox">
            <a href="#contact_form" data-transition="none" __data-bs-toggle="modal" __data-bs-target="#contact-details">Contact Us</a>
            <span class="menuTip">Get in touch</span>
        </li>
        <li class="linkBox">
            <a href="#" data-transition="none">More</a>
            <span class="menuTip">More Information</span>
            <!-- ul>li*4>a -->
            <ul class="subMenu" style="width: 15rem";>
                <li><a href="#the-bridge-care" data-transition="none" class="">Bridge care program</a></li>
                <li><a href="#the-tabitha-care" data-transition="none" class="">Tabitha care program</a></li>
                <li><a href="#glory-house" data-transition="none" class="">Glory house program</a></li>
                
            </ul>
        </li>
        
    </ul>`;

  const navMenuElement = document.querySelectorAll('.navigationBar');
  const navMenuElementArr = Array.from(navMenuElement);
  for (const cur of navMenuElementArr) {
    cur.innerHTML = navMenu;
  }

  const headerImage = `<a href="#home"><img src="img/logo_2.png" alt="Jars of Glory" class="logo d-none d-lg-block d-xl-block ms-3"></a>`;
  const headerElement = document.querySelectorAll('.ui_Content_Header');
  for (let i = 0; i < headerElement.length; i++) {
    headerElement[i].innerHTML = headerImage;
  }
}

/*
{
  //Sticky Navigation
  var uiSitePages = document.querySelectorAll('.ui-sitePages');
  var uiSitePagesArr = Array.from(uiSitePages);
  function stickyNavigation() {
    const nav = document.querySelectorAll('.navigationBar');
    const navArr = Array.from(nav);
    let navTop = 47;
    var scrollY = window.scrollY;
    for (const cur of navArr) {
      //window.scrollY > navTop ? cur.classList.add('sticky') : cur.classList.remove('sticky');
      if (window.scrollY > navTop) {
        cur.classList.add('sticky');
        for (const cur of uiSitePagesArr) {
          cur.style.marginTop = 140 + 'px';
        }
        // document.querySelector('.ui-sitePages').style.marginTop = 124 + 'px';
      } else {
        cur.classList.remove('sticky');
        for (const cur of uiSitePagesArr) {
          cur.style.marginTop = 1.5 + 'rem';
        }
        // document.querySelector('.ui-sitePages').style.marginTop = 0 + 'px';
      }
    }
  }
  window.addEventListener('scroll', stickyNavigation);
}
*/

{
  // Reveal Services

  // The Bridge Service
  if (dom.bridgeCareBtn) {}
  dom.bridgeCareBtn.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.bridgeCareBtn')) {
      dom.theBridgeCareService.classList.toggle('d-none');
    }
  });
  if (dom.bridgeScholarshipBtn) {
    dom.bridgeScholarshipBtn.addEventListener('pointerdown', (e) => {
      if (e.target.closest('.bridgeScholarshipBtn')) {
        dom.theBridgeScholarship.classList.toggle('d-none');
      }
    });
  }
}


{
  // Read more
  const readControl = document.querySelector('.read-control');
  const readIcon = document.querySelector('.read-icon');
  const read = document.querySelector('.read');
  if (readControl) {
    // console.log(readControl);
    readControl.addEventListener('pointerdown', (e) => {
      readControl.textContent = '';
      if (read.classList.contains('d-none')) {
        read.classList.toggle('d-none');
        // readControl.textContent = '<i class="bi bi-arrows-collapse"></i>';
        readIcon.classList.add('bi-arrows-collapse')
        readIcon.classList.remove('bi-arrows-expand')
        readControl.setAttribute('data-bs-original-title', 'Read Less');
        readControl.insertAdjacentHTML('afterbegin', '<i class="bi bi-arrows-collapse"></i>');
        readControl.insertAdjacentHTML('beforeend', 'Read Less...');
        // readControl.textContent = 'Read Less...';
      } else {
        read.classList.toggle('d-none');
        readIcon.classList.add('bi-arrows-expand')
        readIcon.classList.remove('bi-arrows-collapse')
        readControl.setAttribute('data-bs-original-title', 'Read More');
        readControl.insertAdjacentHTML('afterbegin', '<i class="bi bi-arrows-expand"></i>');
        readControl.insertAdjacentHTML('beforeend', 'Read More...');
        // readControl.textContent = 'Read More...';
      }
    });
  }
}

/*
// Remove Anchor tags (hash) from SPA URLs
const section = document.querySelectorAll('a[href*="#"]');

for (const cur of section) {
  let counter = 0;
  cur.addEventListener('click', (e) => {
    counter++;
    var hash = window.location.hash;
    $.mobile.changePage(hash, {changeHash:false});
    // var section = e.target.href.split('/')[3].slice(1);//.split('-')[1];
    // console.log(section);
    // console.log(location);
    // console.log(location.pathname);
    // setTimeout(() => {
    //   // history.replaceState('', '', window.location.origin + window.location.pathname + window.location.search);
    //   history.replaceState('', '', window.location.origin + counter <= 1 ? window.location.pathname : window.location.pathname.slice(0, 1) + section);
    // }, 3);
  });
}
*/

{
  // SHOW HIDE OPTIONS LIST
  const tabContent = document.querySelector('.tabContent');
  const optionsCheckBox = Array.from(document.querySelectorAll('.options-checkbox'));
  const optionsList = Array.from(document.querySelectorAll('.options-list'));
  if (tabContent) {
    for (const checkBox of optionsCheckBox) {
      checkBox.setAttribute('checked', 'checked');
      checkBox.addEventListener('change', (e) => {
        console.log(tabContent);
        for (const list of optionsList) {
          if (e.target.checked === false) {
            console.log(e.target.checked);
            list.classList.add('d-none');
          } else {
            console.log(e.target.checked);
            list.classList.remove('d-none');
          }
        }
      });
    }
  }
}


{
  // Inject donation Iframe
  const theBridge1Iframe = '<iframe src="https://donorbox.org/embed/the-bridge-1" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.theBridgeCareTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.the-bridge-1-Tab') && dom.theBridge1.classList.contains('noIframeLoaded')) {
      dom.theBridge1.insertAdjacentHTML('afterbegin', theBridge1Iframe);
      dom.theBridge1.classList.remove('noIframeLoaded');

    }
  });

  const theBridge12Iframe = '<iframe src="https://donorbox.org/embed/the-bridge-1-2" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.theBridgeScholarshipTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.the-bridge-1-2-Tab') && dom.theBridge12.classList.contains('noIframeLoaded')) {
      dom.theBridge12.insertAdjacentHTML('afterbegin', theBridge12Iframe);
      dom.theBridge12.classList.remove('noIframeLoaded');

    }
  });

  const tthssIframe = '<iframe src="https://donorbox.org/embed/tabitha-transitional-housing-support-services" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.tthssTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.tthssTab') && dom.tthss.classList.contains('noIframeLoaded')) {
      dom.tthss.insertAdjacentHTML('afterbegin', tthssIframe);
      dom.tthss.classList.remove('noIframeLoaded');

    }
  });

  const tcttIframe = '<iframe src="https://donorbox.org/embed/tabitha-career-technical-training" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.tcttTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.tcttTab') && dom.tctt.classList.contains('noIframeLoaded')) {
      dom.tctt.insertAdjacentHTML('afterbegin', tcttIframe);
      dom.tctt.classList.remove('noIframeLoaded');

    }
  });

  const tfeIframe = '<iframe src="https://donorbox.org/embed/tabitha-financial-education" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.tfeTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.tfeTab') && dom.tfe.classList.contains('noIframeLoaded')) {
      dom.tfe.insertAdjacentHTML('afterbegin', tfeIframe);
      dom.tfe.classList.remove('noIframeLoaded');

    }
  });

  const tstrcIframe = '<iframe src="https://donorbox.org/embed/tabitha-short-term-recovery-care" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.tstrcTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.tstrcTab') && dom.tstrc.classList.contains('noIframeLoaded')) {
      dom.tstrc.insertAdjacentHTML('afterbegin', tstrcIframe);
      dom.tstrc.classList.remove('noIframeLoaded');

    }
  });

  const ghIframe = '<iframe src="https://donorbox.org/embed/glory-house-1" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style="max-width: 500px; min-width: 380px; max-height:none!important"></iframe>';

  dom.ghTab.addEventListener('pointerdown', (e) => {
    if (e.target.closest('.ghTab') && dom.gh.classList.contains('noIframeLoaded')) {
      dom.gh.insertAdjacentHTML('afterbegin', ghIframe);
      dom.gh.classList.remove('noIframeLoaded');

    }
  });



}

{
  // Flexible Textarea in form
  $(document).ready(function () {
    $('textarea').on('input', function () {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight) + 'px';
    });
  });
}

/*
{
  // Form Submission
  let fullname = document.getElementById('fullname');
  let email = document.getElementById('email');
  let comment = document.getElementById('comment');
  dom.submitForm.addEventListener('pointerdown', (e) => {
    if(e.target) {
      e.preventDefault();
      if(fullname.value === "" || email.value === "" || comment.value === "") {
        const errormsg = document.querySelector('.errormsg');
        errormsg.classList.remove('d-none');
        errormsg.textContent = "One or all of your entries have error. Please complete all required fields";
      } else {
        console.log(fullname.value);
        console.log(email.value);
        console.log(comment.value);
        
      }
    }
  });
}
*/

// jQuery(document).ready(function(e) {
//   $('#calendar').clndr();

// });