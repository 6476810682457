$(document).ready(function () {
  $('#main_cf').on('submit', function (e) {
    e.preventDefault();
    var form = $(this);

    $('.success-message').hide();
    $('.error-message').hide();

    var sendFormData = $.ajax({
      url: form.attr('action'),
      data: form.serialize(),
      method: 'POST',
    });

    sendFormData.done(function (data) {
      // console.log('success');
      // console.log(data);
      var response = JSON.parse(data);
      $('.success-message').text(response.message).slideDown();
      $('#fullname, #email, #phone, #address, #city, #state, #zip, #country, #subject, #comment').val('');
    });

    sendFormData.fail(function (data) {
      // console.log('error');
      // console.log(data);
      var response = JSON.parse(data.responseText);

      $('.error-message').text(response.message).slideDown();
    });
  });
});


// const formEl = document.getElementById('form');
// formEl.addEventListener('submit', (e) => {
//   e.preventDefault();
//   e.stopPropagation();
// });


// Run on page load
window.onload = function () {
  // If sessionStorage is storing default values, do not restore data
  if (sessionStorage && sessionStorage.getItem('fullname') == 'name') {
    return;
  }

  // If values are not blank, restore them to the fields
  var cffname = sessionStorage.getItem('cffullname');
  if (cffname !== null) $('#fullname').val(cffname);

  var cfemail = sessionStorage.getItem('cfemail');
  if (cfemail !== null) $('#email').val(cfemail);

  var cfphone = sessionStorage.getItem('cfphone');
  if (cfphone !== null) $('#phone').val(cfphone);

  var cfaddress = sessionStorage.getItem('cfaddress');
  if (cfaddress !== null) $('#address').val(cfaddress);

  var cfcity = sessionStorage.getItem('cfcity');
  if (cfcity !== null) $('#city').val(cfcity);
  
  var cfstate = sessionStorage.getItem('cfstate');
  if (cfstate !== null) $('#state').val(cfstate);

  var cfzip = sessionStorage.getItem('cfzip');
  if (cfzip !== null) $('#zip').val(cfzip);

  var cfcountry = sessionStorage.getItem('cfcountry');
  if (cfcountry !== null) $('#country').val(cfcountry);

  var cfsubject = sessionStorage.getItem('cfsubject');
  if (cfsubject !== null) $('#subject').attr('selected', cfsubject);

  var cfcomment = sessionStorage.getItem('cfcomment');
  if (cfcomment !== null) $('#comment').val(cfcomment);
};

// Before refreshing the page, save the form data to sessionStorage
window.onbeforeunload = function () {
  sessionStorage.setItem('cffullname', $('#fullname').val());
  sessionStorage.setItem('cfemail', $('#email').val());
  sessionStorage.setItem('cfphone', $('#phone').val());
  sessionStorage.setItem('cfaddress', $('#address').val());
  sessionStorage.setItem('cfcity', $('#city').val());
  sessionStorage.setItem('cfstate', $('#state').val());
  sessionStorage.setItem('cfzip', $('#zip').val());
  sessionStorage.setItem('cfcountry', $('#country').val());
  sessionStorage.setItem('cfsubject', $('#subject').attr('selected'));
  sessionStorage.setItem('cfcomment', $('#comment').val());
};
