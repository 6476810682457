// Addition function
export function addition(a, b) {
  console.log(a + b);
}

// DOM ELEMENTS
export const dom = {
  getFullYear: document.querySelectorAll('.getFullYear'),
  siteFooter: document.querySelectorAll('.siteFooter'),
  uiPanelMenu: document.getElementById('ui-PanelMenu'),
  jsCover: document.getElementById('jsCover'),
  jsGdpr: document.getElementById('js__gdpr'),
  gdpr: document.querySelector('.gdpr'),
  gdprRemove: document.querySelector('.gdprRemove'),
  jsCloseGdpr: document.getElementById('js__close__gdpr'),
  appContainer: document.getElementById('appContainer'),
  appBody: document.getElementById('appBody'),
  backToTopBtn: document.getElementById('backToTop'),
  navigationBar: document.querySelectorAll('.navigationBar'),
  bridgeCareBtn: document.querySelector('.bridgeCareBtn'),
  bridgeScholarshipBtn: document.querySelector('.bridgeScholarshipBtn'),
  theBridgeCareService: document.getElementById('theBridgeCareService'),
  theBridgeScholarship: document.getElementById('theBridgeScholarship'),
  submitForm: document.getElementById('submit-contact-form'),
  give: document.getElementById('give'),
  theBridgeCareTab: document.querySelector('.the-bridge-1-Tab'),
  theBridge1: document.querySelector('.the-bridge-1'),
  theBridgeScholarshipTab: document.querySelector('.the-bridge-1-2-Tab'),
  theBridge12: document.querySelector('.the-bridge-1-2'),
  tthssTab: document.querySelector('.tthssTab'),
  tthss: document.querySelector('.tthss'),
  tcttTab: document.querySelector('.tcttTab'),
  tctt: document.querySelector('.tctt'),
  tfeTab: document.querySelector('.tfeTab'),
  tfe: document.querySelector('.tfe'),
  tstrcTab: document.querySelector('.tstrcTab'),
  tstrc: document.querySelector('.tstrc'),
  ghTab: document.querySelector('.ghTab'),
  gh: document.querySelector('.gh'),
};
